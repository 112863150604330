@use "../designSystem/common/sassVars" as *;

.tourList {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
	gap: 20px;

	@media (min-width: 590px) and (max-width: 1255px) {
		grid-template: 1fr 1fr / 1fr 1fr;
	}

	@media (min-width: $scr-sm-min) {
		gap: 30px;
	}

	a {
		border: 1px solid var(--grey-400);
		background-clip: border-box;
		color: #000;
		font-size: 22px;
		line-height: 26px;
		padding: 20px;
		text-align: center;
		border-radius: var(--rad);

		&:hover {
			border-color: #000;
			position: relative;
		}

		figure {
			display: block;
			position: relative;
			margin: 20px 0 40px;

			&::after {
				content: '';
				display: block;
				padding-top: 80px;
			}
		}

		img {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			max-height: 80px;
		}
	}

}
